import { GlobalContext } from 'containers/MediaMarkt/globalContext';
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.scss';
import { MediaMarkt } from './containers/MediaMarkt';
import { NotFound } from './components/NotFound';
import { Routes } from 'global/Routes';
import Quiz from 'components/QuizPce/QuizPce';
//import { ElCampaign } from './components/ElCampaign/ElCampaign';
import { ELON } from './components/ELON/ELON';
import Hotjar from '@hotjar/browser';

const siteId = 5071905;
const hotjarVersion = 6;
Hotjar.init(siteId, hotjarVersion);

function App() {
  return (
    <GlobalContext>
      <Router>
        <Switch>
          <Route exact path={[Routes.EVENT, Routes.TIMPRIS, Routes.PCE]}>
            <MediaMarkt />
          </Route>
          <Route path={Routes.QUIZ}>
            <Quiz />
          </Route>
          <Route path={Routes.ELON}>
            <ELON />
          </Route>
          {/*<Route exact path={Routes.EL}>
            <ElCampaign />
          </Route>*/}
          <Route>
            <NotFound />
          </Route>
        </Switch>
      </Router>
    </GlobalContext>
  );
}
export default App;
