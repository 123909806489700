import React, { useCallback, useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { format, addDays } from 'date-fns';

import { Text } from 'components/Text';
import { Input } from 'components/Input';
import { RadioButton } from 'components/Buttons';
import { Loader } from 'components/Loader';
import ConditionalRender from 'components/ConditionalRender';

import { dateFormats } from 'utils/constants';
import { areaCodeWithoutLetters } from 'utils/areaCode';
import { endpoints } from 'global/endpoints';
import styles from './styles.module.scss';
import {
  UPDATE_USER_INFORMATION,
  UPDATE_SAME_ADDRESS,
  UPDATE_MOVE_STATUS,
  CHECK_ADDRESS,
} from 'utils/reducerConstants';
import {
  postalCodeValidation,
  handleOnFocus,
  lengthValidation,
} from 'utils/userInformationValidations';

import BottomButtons from '../BottomButtons';
import { existingAddress } from 'utils/constants';
import { GlobalContextContainer } from 'containers/MediaMarkt/globalContext';

import { pathLocationCheck } from 'utils/shortCodeOrganisation';
import { Routes } from 'global/Routes';
import { useLocation } from 'react-router';

const UserInformation = ({
  handleShowBillingAddress,
  assignment,
  setAssignmentData,
  stepsList,
  setCurrentStep,
  setStepsList,
  currentStep,
  makeCallFromLocalStorage,
  setIsOnline,
  isOpen,
}) => {
  const [loading, setLoading] = useState(false);
  const [stepValidations, setStepValidations] = useState({});
  const [validStep, setStepValid] = useState(false);
  const [apiConnection, setApiConnection] = useState();
  const sameAddressSelection = assignment.data.invoice_address === '1';
  const movement = assignment.data.move_status === '1' ? true : false;
  const userData = assignment.data;
  const { userExisting } = assignment;
  const {
    setFacilityStartDate,
    startDate,
    setStartDateProxyOne,
    setValidation,
    setCompletedFirstStep,
  } = useContext(GlobalContextContainer);

  const location = useLocation();

  const handleInputChange = (val, address) => {
    setAssignmentData({
      type: UPDATE_USER_INFORMATION,
      payload: { ...assignment.data, [val.target.name]: val.target.value },
    });

    if (address) {
      setAssignmentData({
        type: CHECK_ADDRESS,
        payload: existingAddress.nonExisting,
      });
    }
  };

  const checkAddress = useCallback(() => {
    let endpoint = `${endpoints.addressCheck}?customer_id=${
      userExisting.customerId
    }&address1=${assignment.data.address.trim()}&zip_code=${areaCodeWithoutLetters(
      assignment.data.zip_code
    )}`;
    if (navigator.onLine) {
      setLoading(true);
      axios
        .get(endpoint)
        .then((res) => {
          setApiConnection(true);
          setAssignmentData({
            type: CHECK_ADDRESS,
            payload: existingAddress.nonExisting,
          });
          setLoading(false);
        })
        .catch((err) => {
          if (err.response.status === 503) {
            setApiConnection(false);
            setValidation(0);
          }
          if (
            err.response &&
            err.response.data.data === 'Den här addressen finns redan'
          ) {
            setAssignmentData({
              type: CHECK_ADDRESS,
              payload: existingAddress.existing,
            });
          } else {
            console.log(err);
          }
          setLoading(false);
        });
    }
  }, [
    userExisting.customerId,
    setValidation,
    assignment.data.address,
    assignment.data.zip_code,
    setAssignmentData,
  ]);

  const setInputsVidation = useCallback(() => {
    let newState = {};
    const inputsNames = [
      'first_name',
      'last_name',
      'address',
      'city',
      'zip_code',
    ];
    inputsNames.forEach((el) =>
      (
        el === 'zip_code'
          ? postalCodeValidation(userData[el])
          : lengthValidation(userData[el])
      )
        ? (newState = { ...newState, [el]: true })
        : (newState = { ...newState, [el]: false })
    );
    setStepValidations(newState);
  }, [userData]);

  const handleStepValidation = () => {
    setInputsVidation();
    if (userExisting && userExisting.customerId) {
      setInputsVidation();
      if (!validStep && postalCodeValidation(userData.zip_code)) {
        checkAddress();
      }
    }
  };

  const handleClickNextButton = () => {
    handleStepValidation();
  };

  const newUserFieldsVal = useCallback(() => {
    return (
      lengthValidation(userData.first_name) &&
      lengthValidation(userData.last_name) &&
      lengthValidation(userData.address) &&
      lengthValidation(userData.city) &&
      postalCodeValidation(userData.zip_code)
    );
  }, [
    userData.address,
    userData.city,
    userData.first_name,
    userData.last_name,
    userData.zip_code,
  ]);

  const existingUserFieldsVal = useCallback(() => {
    return (
      stepValidations.first_name &&
      stepValidations.last_name &&
      stepValidations.address &&
      stepValidations.city &&
      stepValidations.zip_code
    );
  }, [
    stepValidations.address,
    stepValidations.city,
    stepValidations.first_name,
    stepValidations.last_name,
    stepValidations.zip_code,
  ]);

  useEffect(() => {
    window.onoffline = () => {
      setIsOnline(false);
      if (isOpen) {
        setValidation(0);
      }
      if (newUserFieldsVal()) {
        setStepValid(true);
      }
    };

    window.ononline = () => {
      makeCallFromLocalStorage();

      if (!isOpen) {
        setValidation(1);
      }
      setIsOnline(true);
      setStepValid(false);
    };

    if (!navigator.onLine) {
      if (newUserFieldsVal()) {
        setStepValid(true);
      } else setStepValid(false);
    } else {
      if (!userExisting.customerId && newUserFieldsVal()) {
        setStepValid(true);
      } else if (
        userExisting.customerId &&
        assignment.addressExisting === existingAddress.nonExisting &&
        existingUserFieldsVal()
      ) {
        setStepValid(true);
      }
    }
  }, [
    assignment.addressExisting,
    existingUserFieldsVal,
    newUserFieldsVal,
    userExisting.customerId,
    validStep,
    isOpen,
    setIsOnline,
    setValidation,
    makeCallFromLocalStorage,
  ]);

  useEffect(() => {
    apiConnection === false && setStepValid(true);
  }, [setStepValid, apiConnection]);

  const handleSameAddress = (value) => {
    setAssignmentData({
      type: UPDATE_SAME_ADDRESS,
      payload: value,
    });
  };
  const fourDaysFromNow = addDays(new Date(), 4);

  const handleMovement = (value) => {
    if (
      pathLocationCheck(location.pathname) === Routes.ELON ||
      pathLocationCheck(location.pathname) === Routes.PCE
    ) {
      setStartDateProxyOne(
        format(addDays(new Date(), 21), dateFormats.yearMonthDay)
      );
    } else if (
      (value === '2' &&
        !pathLocationCheck(location.pathname) === Routes.ELON) ||
      !pathLocationCheck(location.pathname) === Routes.PCE
    ) {
      setStartDateProxyOne(format(fourDaysFromNow, dateFormats.yearMonthDay));
    } else {
      setFacilityStartDate(startDate);
    }
    setAssignmentData({
      type: UPDATE_MOVE_STATUS,
      payload: value,
    });
  };
  useEffect(() => {
    setCompletedFirstStep(true);
  }, [setCompletedFirstStep]);

  return (
    <div>
      <Text medium>
        Hej! Det är enkelt att beställa billig och grön el från oss. Kontrollera
        bara att dina uppgifter nedan stämmer, så är du strax klar.
      </Text>
      <div className={styles.inputsWrap}>
        <div className={styles.sameAddressWrap}>
          <Text medium>
            Är detta kundens huvudsakliga adress (dvs, är det också hit fakturan
            ska skickas)?
          </Text>
          <div className={styles.twoColumnsRadioButton}>
            <RadioButton
              value={true}
              id="sameAdressYes"
              name="sameAddress"
              label="Ja"
              isChecked={sameAddressSelection}
              onChange={() => {
                handleSameAddress('1');
                handleShowBillingAddress(false);
              }}
            />
            <RadioButton
              id="sameAdressNo"
              value={false}
              name="sameAddress"
              label="Nej"
              isChecked={!sameAddressSelection}
              onChange={() => {
                handleSameAddress('0');
                handleShowBillingAddress(true);
              }}
            />
          </div>
        </div>
        <div className={styles.twoColumns}>
          <Input
            required={true}
            label="Förnamn"
            placeholder="Förnamn..."
            large
            name="first_name"
            value={assignment.data.first_name || ''}
            onChange={(e) => {
              handleInputChange(e);
            }}
            onFocus={(e) => handleOnFocus(e.target.name, setStepValidations)}
            error={stepValidations.first_name === false}
            outline
          />
          <Input
            required={true}
            label="Efternamn"
            placeholder="Efternamn..."
            large
            name="last_name"
            value={assignment.data.last_name || ''}
            onChange={(e) => handleInputChange(e)}
            onFocus={(e) => handleOnFocus(e.target.name, setStepValidations)}
            error={stepValidations.last_name === false}
            outline
          />
        </div>
        <div className={styles.twoColumns}>
          <Input
            required={true}
            label="Adress"
            placeholder="Adress..."
            large
            name="address"
            value={assignment.data.address || ''}
            onChange={(e) => handleInputChange(e, 'address')}
            onFocus={(e) => handleOnFocus(e.target.name, setStepValidations)}
            error={stepValidations.address === false}
            outline
          />
          <div className={styles.twoColumns}>
            <Input
              required={true}
              label="Postkod"
              placeholder="Postkod..."
              large
              name="zip_code"
              value={assignment.data.zip_code || ''}
              onChange={(e) => handleInputChange(e, 'address')}
              onFocus={(e) => handleOnFocus(e.target.name, setStepValidations)}
              error={stepValidations.zip_code === false}
              maxLength={7}
              outline
            />
            <Input
              required={true}
              label="Postort"
              placeholder="Postort..."
              large
              name="city"
              value={assignment.data.city || ''}
              onChange={(e) => handleInputChange(e)}
              onFocus={(e) => handleOnFocus(e.target.name, setStepValidations)}
              error={stepValidations.city === false}
              outline
            />
          </div>
        </div>

        <div className={styles.movingInWrap}>
          <Text medium>Ska du flytta in?</Text>
          <div className={styles.twoColumnsRadioButton}>
            <RadioButton
              value={true}
              id="movingInYes"
              name="movingIn"
              label="Ja"
              isChecked={!movement}
              onChange={() => {
                handleMovement('2');
              }}
            />
            <RadioButton
              value={false}
              id="movingInNo"
              name="movingIn"
              label="Nej"
              isChecked={movement}
              onChange={() => {
                handleMovement('1');
              }}
            />
          </div>
        </div>
      </div>
      <ConditionalRender shouldRender={loading}>
        <Loader />
      </ConditionalRender>
      <ConditionalRender
        shouldRender={assignment.addressExisting === existingAddress.existing}
      >
        <Text medium className={styles.error}>
          Den här addressen finns redan
        </Text>
      </ConditionalRender>
      <BottomButtons
        stepsList={stepsList}
        setCurrentStep={setCurrentStep}
        setStepsList={setStepsList}
        currentStep={currentStep}
        validStep={validStep}
        handleClick={handleClickNextButton}
        disabled={
          loading || assignment.addressExisting === existingAddress.existing
        }
      />
    </div>
  );
};

export default UserInformation;
