import React from 'react';
import classnames from 'classnames';
import styles from './styles.module.scss';
import imageCompression from 'browser-image-compression';

export const UploadImage = ({ className, handleUploadedPhoto, fileName }) => {
  const handleImage = async (e) => {
    const file = e.target.files[0];

    const options = {
      maxSizeMB: 0.17,
      maxWidthOrHeight: 1024,
      useWebWorker: true,
    };
    try {
      const la = await imageCompression(file, options);
      var reader = new FileReader();
      reader.readAsDataURL(la);
      reader.onloadend = () => {
        var base64 = reader.result.replace('data:', '').replace(/^.+,/, '');
        handleUploadedPhoto({
          fileName: file.name,
          file: base64,
        });
      };
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div
      className={classnames(className, styles.uploadPhotoWrapper)}
      title={fileName}
    >
      <input
        type="file"
        accept="image/*"
        id="image-picker"
        onChange={handleImage}
        capture="environment"
        className={styles.hidden}
      />
      <label htmlFor="image-picker" className={styles.chooseFile}>
        Take picture
      </label>
      <label className={styles.fileName}>{fileName || 'No file chosen'}</label>
    </div>
  );
};
