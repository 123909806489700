import React, { useContext, useEffect, useState, useCallback } from 'react';
import { format, addDays } from 'date-fns';
import axios from 'axios';

import { Text } from 'components/Text';
import { RadioButton } from 'components/Buttons';
import { Input } from 'components/Input';
import { DatePickerComponent } from 'components/DatePicker';
import BottomButtons from '../BottomButtons';
import { Loader } from 'components/Loader';

import {
  currentFacilityInfo,
  newFacilityInfo,
  fourDaysFromNow,
  dateFormats,
} from 'utils/constants';

import styles from './styles.module.scss';
import ConditionalRender from 'components/ConditionalRender';
import { endpoints } from 'global/endpoints';
import { characterCounter, validatePlantId } from 'utils/facilityInformation';
import {
  UPDATE_FACILITY_INFORMATION_AREA_ID,
  UPDATE_FACILITY_INFORMATION_PLANT_ID,
  UPDATE_PROXY,
  UPDATE_FACILITY_INFORMATION_CONTRACT_START,
} from 'utils/reducerConstants';
import { GlobalContextContainer } from 'containers/MediaMarkt/globalContext';

import { pathLocationCheck } from 'utils/shortCodeOrganisation';
import { Routes } from 'global/Routes';
import { useLocation } from 'react-router';

const FacilityInformation = ({
  stepsList,
  setCurrentStep,
  setStepsList,
  currentStep,
  assignment,
  setAssignmentData,
}) => {
  const {
    facilityStartDate,
    startDateProxyOne,
    startDate,
    setFacilityStartDate,
    setStartDateProxyOne,
    setValidation,
  } = useContext(GlobalContextContainer);

  const radioButtonData = [
    {
      id: 1,
      name: 'currentFacilityInfo',
      value: '',
      label: currentFacilityInfo,
      showDatepicker: assignment.data.move_status === '2',
    },
    {
      id: 0,
      name: 'newFacilityInfo',
      value: '',
      label: newFacilityInfo,
      showDatepicker: false,
    },
  ];
  const [checkedValue, setIsChecked] = useState(
    parseInt(assignment.data.proxy)
  );

  const location = useLocation();

  const [stepValidation, setStepValidation] = useState(false);
  const [showErrorBorder, setShowErrorBorder] = useState({});
  const [plantId, setPlantId] = useState('');
  const [areaId, setAreaId] = useState('');
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [lowScore, setLowScore] = useState();

  const isStepValid = useCallback(() => {
    return (
      validatePlantId(assignment.data.object_id || plantId, '735999') &&
      characterCounter(assignment.data.net_area, 3)
    );
  }, [assignment.data.net_area, assignment.data.object_id, plantId]);

  const creditCheck = useCallback(() => {
    if (navigator.onLine && assignment.userExisting.creditCheck) {
      setLoading(true);
      axios
        .get(
          `${endpoints.noExistingUser}?personal_number=${assignment.data.soc_id}`
        )
        .then((res) => {
          setData(res.status === 200);
          setLowScore(false);
          setLoading(false);
        })
        .catch((err) => {
          if (err.response.status === 503) {
            setStepValidation(true);
            setValidation(0);
            setLoading(false);
          } else {
            setData(false);
            setLoading(false);
            setLowScore(true);
            console.log(err);
          }
        });
    } else if (assignment.addressExisting !== 'non-existing') {
      setData(true);
      setLowScore(false);
    }
  }, [
    assignment.addressExisting,
    assignment.data.soc_id,
    assignment.userExisting.creditCheck,
    setValidation,
  ]);
  const handleAreaId = (value) => {
    setAreaId(value);
    setAssignmentData({
      type: UPDATE_FACILITY_INFORMATION_AREA_ID,
      payload: value,
    });
  };

  const handlePlantId = (value) => {
    setPlantId(value);
    setAssignmentData({
      type: UPDATE_FACILITY_INFORMATION_PLANT_ID,
      payload: value,
    });
  };
  const handlevalidationFields = useCallback(() => {
    if (checkedValue === 0) {
      setShowErrorBorder({
        areaId: characterCounter(assignment.data.net_area, 3),
        plantId: validatePlantId(
          assignment.data.object_id || plantId,
          '735999'
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areaId, checkedValue, plantId]);

  useEffect(() => {
    if (!assignment.userExisting.creditCheck && checkedValue === 1) {
      setStepValidation(true);
    } else {
      setStepValidation(false);
    }
  }, [assignment.userExisting.creditCheck, checkedValue]);

  const currentPlantInfo = () => {
    if (checkedValue === 0 && !isStepValid()) {
      setStepValidation(false);
    } else setStepValidation(true);
    handlevalidationFields();
    if (!loading && lowScore === undefined) {
      creditCheck();
    }
  };

  useEffect(() => {
    if (
      stepsList[currentStep + 1].selected &&
      stepsList[currentStep + 2].completed &&
      assignment.data.object_id !== ''
    ) {
      handlevalidationFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep, stepsList]);

  const handleStartDate = useCallback(
    (value) => {
      setAssignmentData({
        type: UPDATE_FACILITY_INFORMATION_CONTRACT_START,
        payload: value,
      });
    },
    [setAssignmentData]
  );
  useEffect(() => {
    const conditionToSendDate = () => {
      if (assignment.data.move_status === '2') {
        return startDateProxyOne;
      } else if (
        assignment.data.move_status === '1' &&
        assignment.data.proxy === '1'
      ) {
        return '';
      } else return facilityStartDate;
    };

    handleStartDate(conditionToSendDate());
  }, [
    assignment.data.move_status,
    assignment.data.proxy,
    facilityStartDate,
    handleStartDate,
    startDateProxyOne,
  ]);
  const handleSetIsChecked = (id) => {
    resetAreaInformation();
    handleStartDate(
      assignment.data.move_status === '2'
        ? format(fourDaysFromNow, dateFormats.yearMonthDay)
        : startDate
    );
    setFacilityStartDate(
      assignment.data.move_status === '2'
        ? format(fourDaysFromNow, dateFormats.yearMonthDay)
        : startDate
    );
    setStartDateProxyOne(
      assignment.data.move_status === '2'
        ? format(fourDaysFromNow, dateFormats.yearMonthDay)
        : startDate
    );
    setIsChecked(id);
    setAssignmentData({
      type: UPDATE_PROXY,
      payload: id === 1 ? '1' : '0',
    });
  };
  const resetAreaInformation = () => {
    handleAreaId('');
    handlePlantId('');
  };

  const showLowCreditScoreError = () => {
    return (
      <div className={styles.lowScoreWrap}>
        <Text error>
          Vi måste tyvärr avslå din ansökan om att teckna elavtal. Om du varit
          bosatt utomlands eller har spärrat ditt personnummer för
          kreditkontroll ber vi dig kontakta kundservice för att på så sätt
          kunna slutföra din ansökan om elavtal.
        </Text>
      </div>
    );
  };

  return (
    <div>
      <Text medium className={styles.textWrap}>
        Anläggningsuppgifter:
      </Text>
      <div className={styles.twoColumns}>
        {radioButtonData.map((item) => (
          <div key={item.id}>
            <div>
              <RadioButton
                value={item.value}
                label={item.label}
                name={item.name}
                id={item.id}
                isChecked={checkedValue === item.id}
                onChange={() => handleSetIsChecked(item.id)}
              />
              {item.showDatepicker && checkedValue === 1 && (
                <DatePickerComponent
                  required
                  label="Avtalsstart"
                  setAssignmentData={setAssignmentData}
                  checkedValue={checkedValue}
                  assignment={assignment}
                />
              )}
            </div>
          </div>
        ))}
      </div>
      <ConditionalRender shouldRender={lowScore && checkedValue === 1}>
        {showLowCreditScoreError()}
      </ConditionalRender>
      <ConditionalRender shouldRender={checkedValue === 0}>
        <div className={styles.inputsWrap}>
          <div className={styles.twoColumns}>
            <Input
              label="Områdes-ID"
              placeholder="Områdes-ID..."
              required
              large
              onChange={(e) => handleAreaId(e.target.value)}
              error={showErrorBorder.areaId === false}
              outline
              value={assignment.data.net_area || ''}
            />
            <div />
          </div>
          <div className={styles.twoColumns}>
            <Input
              label="Anläggnings-ID"
              placeholder="Anläggnings-ID..."
              required
              large
              onChange={(e) => handlePlantId(e.target.value)}
              error={showErrorBorder.plantId === false}
              outline
              value={assignment.data.object_id || ''}
            />
            <DatePickerComponent
              required
              label="Avtalsstart"
              setAssignmentData={setAssignmentData}
              checkedValue={checkedValue}
              assignment={assignment}
            />
          </div>
        </div>
      </ConditionalRender>
      <ConditionalRender shouldRender={loading}>
        <Loader />
      </ConditionalRender>
      <ConditionalRender shouldRender={lowScore && checkedValue === 0}>
        {showLowCreditScoreError()}
      </ConditionalRender>
      <BottomButtons
        stepsList={stepsList}
        setCurrentStep={setCurrentStep}
        setStepsList={setStepsList}
        currentStep={currentStep}
        validStep={stepValidation && data !== false && !loading}
        handleClick={() => currentPlantInfo()}
        disabled={data === false || loading}
      />
    </div>
  );
};

export default FacilityInformation;
