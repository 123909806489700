import React, { useEffect, useState } from 'react';
import { WizardStepper } from 'components/WizardStepper';
import {
  SecurityNumber,
  UserInformation,
  FacilityInformation,
  Store,
  Contract,
  BillingAddress,
  SuccessMessage,
} from './components';
import { DefaultAssignment, AssignmentReducer } from 'utils/assignmentReducer';
import { steps } from 'utils/stepsData';
import { stepsNames, billingAddress } from 'utils/constants';
import { useReducer } from 'react';
import { RESET_MODAL } from 'utils/reducerConstants';

export const AssignmentModalContent = ({
  isOpen,
  isMinimized,
  setIsOnline,
  makeCallFromLocalStorage,
  storeData,
  agents,
  priceDataToSend,
  resetKey,
}) => {
  const defaultSteps = steps.slice();
  const [currentStep, setCurrentStep] = useState(
    steps.findIndex((step) => step.selected === true)
  );
  const [stepsList, setStepsList] = useState(steps);
  const selected = stepsList.find((item) => item.selected === true);

  const [assignment, setAssignmentData] = useReducer(
    AssignmentReducer,
    DefaultAssignment
  );

  useEffect(() => {
    if (!isOpen && !isMinimized) {
      setAssignmentData({
        type: RESET_MODAL,
        payload: { DefaultAssignment },
      });
      setStepsList(defaultSteps);
      setCurrentStep(defaultSteps.findIndex((step) => step.selected === true));
    }
    // eslint-disable-next-line
  }, [isMinimized, isOpen]);

  // Conditionally add a useEffect to reset the modal when resetKey changes
  useEffect(() => {
    if (resetKey !== undefined) {
      setAssignmentData({
        type: RESET_MODAL,
        payload: { DefaultAssignment },
      });
      setStepsList(defaultSteps);
      setCurrentStep(defaultSteps.findIndex((step) => step.selected === true));
    }
  }, [resetKey]);

  const handleShowBillingAddress = (value) => {
    let newArray = stepsList;
    if (value) {
      newArray.splice(2, 0, billingAddress);
    } else {
      newArray.splice(2, 1);
    }
    setStepsList(() => [...newArray]);
  };

  const viewToShow = () => {
    if (selected.name === stepsNames.securityNumber) {
      return (
        <SecurityNumber
          assignment={assignment}
          setAssignmentData={setAssignmentData}
          stepsList={stepsList}
          setCurrentStep={setCurrentStep}
          setStepsList={setStepsList}
          currentStep={currentStep}
          setIsOnline={setIsOnline}
          makeCallFromLocalStorage={makeCallFromLocalStorage}
          isOpen={isOpen}
        />
      );
    }
    if (selected.name === stepsNames.userInformation) {
      return (
        <UserInformation
          assignment={assignment}
          setAssignmentData={setAssignmentData}
          handleShowBillingAddress={handleShowBillingAddress}
          stepsList={stepsList}
          setCurrentStep={setCurrentStep}
          setStepsList={setStepsList}
          currentStep={currentStep}
          setIsOnline={setIsOnline}
          isOpen={isOpen}
          makeCallFromLocalStorage={makeCallFromLocalStorage}
        />
      );
    }
    if (selected.name === stepsNames.facilityInformation) {
      return (
        <FacilityInformation
          assignment={assignment}
          setAssignmentData={setAssignmentData}
          stepsList={stepsList}
          setCurrentStep={setCurrentStep}
          setStepsList={setStepsList}
          currentStep={currentStep}
        />
      );
    }
    if (selected.name === stepsNames.store) {
      return (
        <Store
          assignment={assignment}
          setAssignmentData={setAssignmentData}
          stepsList={stepsList}
          setCurrentStep={setCurrentStep}
          setStepsList={setStepsList}
          currentStep={currentStep}
          {...(storeData && { storeData: storeData })}
        />
      );
    }
    if (selected.name === stepsNames.contract) {
      return (
        <Contract
          assignment={assignment}
          setAssignmentData={setAssignmentData}
          stepsList={stepsList}
          setCurrentStep={setCurrentStep}
          setStepsList={setStepsList}
          currentStep={currentStep}
          agents={agents}
          {...(priceDataToSend && { priceDataToSend: priceDataToSend })}
        />
      );
    }
    if (selected.name === stepsNames.billingAddress) {
      return (
        <BillingAddress
          assignment={assignment}
          setAssignmentData={setAssignmentData}
          stepsList={stepsList}
          setCurrentStep={setCurrentStep}
          setStepsList={setStepsList}
          currentStep={currentStep}
        />
      );
    }
    if (selected.name === stepsNames.success) {
      return (
        <SuccessMessage
          stepsList={stepsList}
          setCurrentStep={setCurrentStep}
          setStepsList={setStepsList}
          currentStep={currentStep}
          setAssignmentData={setAssignmentData}
          defaultAssignment={DefaultAssignment}
        />
      );
    }
  };

  return (
    <div>
      <WizardStepper
        stepsList={stepsList}
        setStepsList={setStepsList}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        uploadImage
      >
        <div>{viewToShow()}</div>
      </WizardStepper>
    </div>
  );
};
