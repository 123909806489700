import React, { useState, useEffect } from 'react';
import { ToggleSwitch } from 'components/Buttons';
import { Text } from 'components/Text';
import ConditionalRender from 'components/ConditionalRender';
import { Row, Column } from 'components/Grid';
import { UploadImage } from 'components/UploadImage';
import { Loader } from 'components/Loader';
import BankIdQRComponent from 'containers/AssignmentModalContent/components/BankId/qr-code';
import styles from './styles.module.scss';
import axios from 'axios';

export const BankId = ({
  value,
  handleBankId,
  signData,
  handleUploadedPhoto,
  fileName,
  handleSignButton,
  handleSignStatus,
  handleOrderRef,
}) => {
  const [isLoading, setLoading] = useState(false);
  const [errorMessage, setError] = useState('');
  const [bankIdEnabled, setBankIdEnabled] = useState(navigator.onLine);
  const [toggleValue, setToggleValue] = useState(value);
  const [showQR, setShowQR] = useState(false);
  const [qrUrl, setQrUrl] = useState('');
  const [isSignedWithBankId, setIsSignedWithBankId] = useState(false);
  const [orderRef, setOrderRef] = useState('');
  const [statusMessage, setStatusMessage] = useState('');

  useEffect(() => {
    setToggleValue(value);
  }, [value]);

  useEffect(() => {
    if (!navigator.onLine) {
      setBankIdEnabled(false);
      handleBankId({ bank_id_sign: false });
      resetQRCode();
    } else {
      setBankIdEnabled(true);
    }
  }, [navigator.onLine]);

  const handleSign = async () => {
    setError('');
    resetQRCode();

    const newValue = !toggleValue;
    setToggleValue(newValue);

    if (!newValue) {
      handleBankId({ bank_id_sign: false });
      return;
    }

    try {
      setLoading(true);
      //console.log('Starting QR sign request with data:', signData);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/contract/QRsign`,
        signData,
        {
          withCredentials: true, // Include cookies in the request
        }
      );
      setLoading(false);

      //console.log('QR sign response received:', response.data);
      if (response.data && response.data.autoStartToken) {
        const returnUrl = `${window.location.protocol}//${window.location.host}/minasidor/bank-landing/?orderRef=${response.data.orderRef}`;
        setQrUrl(
          `bankid:///?autostarttoken=${
            response.data.autoStartToken
          }&redirect=${encodeURIComponent(returnUrl)}`
        );
        setOrderRef(response.data.orderRef);
        setShowQR(true);
        handleBankId({ bank_id_sign: true });
      } else {
        throw new Error('Failed to fetch QR Code');
      }
    } catch (error) {
      console.error('Error initiating BankID sign process:', error);
      setError('Failed to initiate BankID sign process. Please retry.');
      resetQRCode();
      setLoading(false);
    }
  };

  const resetQRCode = () => {
    setShowQR(false);
    setQrUrl('');
    setOrderRef('');
  };

  const handleError = (error) => {
    console.error('QR Sign Check failed:', error);
    setError('Error during sign status check. Please retry.');
    resetQRCode();
    handleBankId({ bank_id_sign: false });
    setToggleValue(false);
  };

  const handleStatusChange = (status, message = '') => {
    console.log(`Status received: ${status} - Message: ${message}`); // Log the received status and message
    setStatusMessage(message); // Update the state with the new message

    if (status === 'complete') {
      // console.log(status, orderRef);
      handleSignStatus(status);
      handleOrderRef(orderRef);

      setShowQR(false);
      setIsSignedWithBankId(true);
      handleSignButton();
    } else if (status === 'failed') {
      setError(message || 'Signing process failed. Please retry.');
      resetQRCode();
      setIsSignedWithBankId(false);
      setToggleValue(false);
    } else if (status === 'pending') {
      setShowQR(true);
      //console.log('Pending status:', message); // Log the pending status
    }
  };

  const showUploadImage = () => {
    return !bankIdEnabled || !(value || isLoading);
  };

  const getTitle = () => {
    return !bankIdEnabled
      ? 'Inloggning med BankID ej möjligt i offline-läge.'
      : '';
  };

  return (
    <div className={styles.container}>
      <Row>
        <Column number="1">
          <ToggleSwitch
            id="bankId"
            isChecked={toggleValue || isLoading}
            handleToggle={handleSign}
            processing={isLoading}
            disabled={!bankIdEnabled}
            title={getTitle()}
          />
        </Column>
        <Column number="2">
          <div className={styles.toggleLabel}>
            <Text medium>
              {isSignedWithBankId
                ? 'Signerat med BankID'
                : 'Använd BankID för signering'}
              <span className={styles.mutedText}>
                [{signData.personal_number}]
              </span>
            </Text>
          </div>
        </Column>
      </Row>
      {showQR && qrUrl && (
        <Row>
          <Column number="1">
            <div className={styles.centered}>
              <BankIdQRComponent
                qrUrl={qrUrl}
                orderRef={orderRef}
                handleStatusChange={handleStatusChange}
                onError={handleError}
                statusMessage={statusMessage}
                //setDisabled={setDisabled}
              />
            </div>
          </Column>
        </Row>
      )}
      <ConditionalRender shouldRender={isLoading || errorMessage}>
        <div className={styles.message}>
          {errorMessage ? (
            <Text xs error>
              {errorMessage}
            </Text>
          ) : (
            <Loader />
          )}
        </div>
      </ConditionalRender>
      <ConditionalRender shouldRender={showUploadImage()}>
        <UploadImage
          handleUploadedPhoto={handleUploadedPhoto}
          fileName={fileName}
        />
      </ConditionalRender>
    </div>
  );
};
